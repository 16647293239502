.main {
  width: calc(100% - 20px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Центрируем содержимое по горизонтали */
  margin-top: 50px;
  .more{
    width: calc(100% - 2px);
    height: 120px;
    border: 1px solid #454545;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
    cursor: pointer;
    transition: .3s;
  }
  .more:hover{
    color: #FFFFFF;
    background-color: #410099;
    transition: .2s;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 10px; /* Пробел между элементами */
  width: calc(100% - 20px);
  max-width: 1920px;
  overflow: hidden;
}

.item {
  position: relative;
  overflow: hidden;
  background-color: #f0f0f0; /* Фон для визуализации элементов */
  //border-radius: 8px;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    transform: scale(1.10);
    transition: 0.2s;
    z-index: 100;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}