input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Для Firefox */
}

@media screen and (min-width: 650px){
  .main{
    position: fixed;
    bottom: 20px;
    right: 20px;
    color: #454545;
    z-index: 10000;
    .cartbtn{
      width: 400px;
      height: 40px;
      padding: 10px 20px;
      border: 1px solid #CCC;
      background-color: #FFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      position: relative;
      .cartbtn_name{}
      .cartbtn_all{
        width: 40px;
        height: 40px;
        background-color: #410099;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
      }


    }



  }
  .opencart{
    position: absolute;
    bottom: 62px;
    right: 0;
    min-height: 100px;
    width: 420px;
    padding: 10px 0px 10px 20px;
    background-color: #FFF;
    z-index: 2;
    border: 1px solid #CCC;
    .opencart_list{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      min-height: 100%;
      max-height: calc(100vh - 102px);
      overflow-x: hidden;
      overflow-y: auto;
      .makesale{
        width: calc(100% - 15px);
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #CCC;
        cursor: pointer;
        transition: 0.2s;
        height: 40px;
        min-height: 40px;
        &:hover{
          transition: 0.2s;
          border: 1px solid #454545;
          color: #FFF;
          background-color: #454545;
        }
      }
      .opencart_prod{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #CCC;
        .opencart_prod_left{
          display: flex;
          width: calc(100% - 130px);
          margin-right: 0px;
          font-size: 12px;
          .opencart_prod_img{
            width: 80px;
            height: 80px;
            margin-right: 5px;
            img{
              width: 80px;
              height: 80px;
            }
          }
        }
        .plusminus{
          width: 120px;
          display: flex;
          height: 50px;
          padding-right: 4px;
          .plusminus_btn{
            width: calc(100% / 3 - 4px);
            border: 1px solid #CCC;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.2s;
            &:hover{
              transition: 0.2s;
              background-color: #CCC;
            }
          }
          .plusminus_total{
            width: calc(100% / 3 - 1px);
            border: none;
            border-top: 1px solid #CCC;
            border-bottom: 1px solid #CCC;
            display: flex;
            justify-content: center;
            align-items: center;

          }
        }

      }

    }
  }
}
@media screen and (max-width: 650px){
  .main{
    position: fixed;
    bottom: 0px;
    right: 0px;
    color: #454545;
    z-index: 10000;
    width: 100vw;
    .cartbtn{
      width: calc(100% - 20px);
      height: 40px;
      padding: 10px 10px;
      border: 1px solid #CCC;
      background-color: #FFF;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      position: relative;
      .cartbtn_name{}
      .cartbtn_all{
        width: 40px;
        height: 40px;
        background-color: #410099;
        color: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
      }


    }



  }
  .opencart{
    position: absolute;
    bottom: 62px;
    right: 0;
    min-height: 100px;
    width: calc(100% - 10px);
    padding: 10px 0px 10px 10px;
    background-color: #FFF;
    z-index: 2;
    border: 1px solid #CCC;
    .opencart_list{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      min-height: 100%;
      max-height: calc(100vh - 102px);
      overflow-x: hidden;
      overflow-y: auto;
      .makesale{
        width: calc(100% - 15px);
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #CCC;
        cursor: pointer;
        transition: 0.2s;
        height: 40px;
        min-height: 40px;
        &:hover{
          transition: 0.2s;
          border: 1px solid #454545;
          color: #FFF;
          background-color: #454545;
        }
      }
      .opencart_prod{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #CCC;
        .opencart_prod_left{
          display: flex;
          width: calc(100% - 130px);
          margin-right: 0px;
          font-size: 12px;
          .opencart_prod_img{
            width: 80px;
            height: 80px;
            margin-right: 5px;
            img{
              width: 80px;
              height: 80px;
            }
          }
        }
        .plusminus{
          width: 120px;
          display: flex;
          height: 50px;
          padding-right: 4px;
          .plusminus_btn{
            width: calc(100% / 3 - 4px);
            border: 1px solid #CCC;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.2s;
            &:hover{
              transition: 0.2s;
              background-color: #CCC;
            }
          }
          .plusminus_total{
            width: calc(100% / 3 - 1px);
            border: none;
            border-top: 1px solid #CCC;
            border-bottom: 1px solid #CCC;
            display: flex;
            justify-content: center;
            align-items: center;

          }
        }

      }

    }
  }
}