@media screen and (min-width: 1600px){
  .main{
    width: 100%;
    height: calc(100% + 4px);
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -2px;
    //background-color: #410099;
    background-image: url("./planetwhite.svg");
    background-size: 100%;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 100px;
    .slashs{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: -30px 0 30px 0;
      .sphere{
        width: 60px;
        height: 60px;
        background-color: #410099;
        border-radius: 30px;
        margin: 0 10px;
      }
      .one{
        opacity: 0.5;
        width: 40px;
        height: 40px;
        animation: sp1 1s ease-in-out infinite;
      }
      .two{
        opacity: 0.7;
        width: 50px;
        height: 50px;
        animation: sp2 1.1s ease-in-out infinite;
      }
      .tree{
        animation: sp3 1.2s ease-in-out infinite;
      }
      .four{
        opacity: 0.7;
        width: 50px;
        height: 50px;
        animation: sp2 1.1s ease-in-out infinite;
      }
      .five{
        opacity: 0.5;
        width: 40px;
        height: 40px;
        animation: sp1 1s ease-in-out infinite;
      }
      @keyframes sp1 {
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(5px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }
      @keyframes sp2{
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(7px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }
      @keyframes sp3{
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(10px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }

    }
    .nameblock{
      width: 100%;
      max-width: 1920px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px 0;
      .title{
        font-size: 60pt;
        font-family: 'ALS';
        color: #410099;
      }

    }
    .container {
      width: 100%;
      min-height: calc(100vh - 1px);
      max-width: 1920px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .blocks.visible {
        opacity: 1;
        transform: translateY(0);
      }
      .blocks{
        width: calc(100% / 3 - 120px);
        height: 800px;
        margin: 20px;
        border: 1px solid #CCC;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.5s ease, transform 0.5s ease;
        .raketa{
          width: 20%;
          animation: drive1 7s infinite;
          position: absolute;
          bottom: -100px;
          right: 160px;
          rotate: -30deg;

        }
        @keyframes drive1 {
          0% {
            bottom: -100px;
            right: 160px;
            rotate: -30deg;
          }
          50% {
            bottom: 100px;
            right: -160px;
            rotate: 30deg;
          }

          80% {
            bottom: 0px;
            right: -360px;
            rotate: 30deg;
          }
          100% {
            bottom: -100px;
            right: 160px;
            rotate: -30deg;
          }
        }
        .planet1{
          width: 20%;
          animation: drive2 7s infinite;
          position: absolute;
          top: -10px;
          right: -30px;
          rotate: 0deg;
        }
        @keyframes drive2 {
          0% {
            top: -10px;
            right: -30px;
            rotate: 0deg;
          }
          50% {
            top: 60px;
            right: -40px;
            rotate: 20deg;
          }
          100% {
            top: -10px;
            right: -30px;
            rotate: 0deg;
          }
        }
        .planet2{
          width: 20%;
          animation: drive3 7s infinite;
          position: absolute;
          top: -60px;
          left: 0px;
        }
        @keyframes drive3 {
          0% {
            top: -60px;
            left: 0px;
          }
          50% {
            top: -70px;
            left: -20px;
          }
          100% {
            top: -60px;
            left: 0px;
          }
        }
        .raketa2{
          width: 40%;
          animation: drive5 9s infinite;
          position: absolute;
          bottom: -200px;
          right: -100px;
          rotate: -75deg;

        }

        @keyframes drive5 {
          0% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
          10% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
          50% {
            bottom: 900px;
            right: -100px;
            rotate: -75deg;
          }
          80% {
            bottom: 900px;
            right: -500px;
            rotate: -75deg;
          }
          90% {
            bottom: -200px;
            right: -500px;
            rotate: -75deg;
          }
          100% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
        }
        .stars{
          width: 15%;
          animation: drive4 9s infinite;
          position: absolute;
          top: -10px;
          right: -10px;
          rotate: 0deg;

        }
        @keyframes drive4 {
          0% {
            rotate: 0deg;
            top: -10px;
          }
          50% {
            rotate: 180deg;
            top: -40px;
          }
          100% {
            rotate: 360deg;
            top: -10px;
          }
        }

        .planet3{
          width: 15%;
          animation: drive9 9s infinite;
          position: absolute;
          top: 5px;
          left: 5px;
          opacity: 0.6;
        }
        .stars2{
          width: 15%;
          animation: drive8 5s infinite;
          position: absolute;
          bottom: -5px;
          right: 200px;
          opacity: 0.8;
        }
        @keyframes drive9 {
          0% {
            opacity: 0.8;
          }
          50% {
            opacity: 0.1;
          }
          100% {
            opacity: 0.8;
          }
        }
        @keyframes drive8 {
          0% {
            opacity: 0.8;
          }
          50% {
            opacity: 0.1;
          }
          100% {
            opacity: 0.8;
          }
        }
        div{
          width: 80%;
          text-align: center;
        }
        .cap{
          font-size: 30pt;
          color: #410099;
          font-weight: 600;
          margin-top: 30px;
        }
        .desc{
          font-size: 16pt;
          font-weight: 600;
          margin-top: 30px;
          height: 80px;
        }
        .price{
          font-size: 30pt;
          font-weight: 600;
          margin-top: 30px;
        }
        .points{
          p{
            font-size: 20pt;
            font-weight: 600;
            margin-top: 20px;
            color: #410099;
          }
        }
        .subscript{

          margin-top: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          .btn{
            padding: 20px 40px;
            border: 1px solid #CCC;
            transition: 0.3s;
            cursor: pointer;
          }
          .btn:hover{
            background-color: #410099;
            color: #FFFFFF;
            transition: 0.3s;
          }
        }
      }

    }

  }
}
@media screen and (min-width: 900px) and (max-width: 1600px){
  .main{
    width: 100%;
    height: calc(100% + 4px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -2px;
    //background-color: #410099;
    background-image: url("./planetwhite.svg");
    background-size: 100%;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 100px;
    z-index: 5;
    .slashs{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: -30px 0 30px 0;
      .sphere{
        width: 60px;
        height: 60px;
        background-color: #410099;
        border-radius: 30px;
        margin: 0 10px;
      }
      .one{
        opacity: 0.5;
        width: 40px;
        height: 40px;
        animation: sp1 1s ease-in-out infinite;
      }
      .two{
        opacity: 0.7;
        width: 50px;
        height: 50px;
        animation: sp2 1.1s ease-in-out infinite;
      }
      .tree{
        animation: sp3 1.2s ease-in-out infinite;
      }
      .four{
        opacity: 0.7;
        width: 50px;
        height: 50px;
        animation: sp2 1.1s ease-in-out infinite;
      }
      .five{
        opacity: 0.5;
        width: 40px;
        height: 40px;
        animation: sp1 1s ease-in-out infinite;
      }
      @keyframes sp1 {
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(5px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }
      @keyframes sp2{
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(7px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }
      @keyframes sp3{
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(10px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }

    }
    .nameblock{
      width: 100%;
      max-width: 1920px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px 0;
      .title{
        font-size: 60pt;
        font-family: 'ALS';
        color: #410099;
      }

    }
    .container {
      width: 100%;
      min-height: calc(100vh - 1px);
      max-width: 1920px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .blocks.visible {
        opacity: 1;
        transform: translateY(0);
      }
      .blocks{
        width: calc(100% / 2 - 80px);
        height: 800px;
        margin: 20px;
        border: 1px solid #CCC;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.5s ease, transform 0.5s ease;
        .raketa{
          width: 20%;
          animation: drive1 7s infinite;
          position: absolute;
          bottom: -100px;
          right: 160px;
          rotate: -30deg;

        }
        @keyframes drive1 {
          0% {
            bottom: -100px;
            right: 160px;
            rotate: -30deg;
          }
          50% {
            bottom: 100px;
            right: -160px;
            rotate: 30deg;
          }

          80% {
            bottom: 0px;
            right: -360px;
            rotate: 30deg;
          }
          100% {
            bottom: -100px;
            right: 160px;
            rotate: -30deg;
          }
        }
        .planet1{
          width: 20%;
          animation: drive2 7s infinite;
          position: absolute;
          top: -10px;
          right: -30px;
          rotate: 0deg;
        }
        @keyframes drive2 {
          0% {
            top: -10px;
            right: -30px;
            rotate: 0deg;
          }
          50% {
            top: 60px;
            right: -40px;
            rotate: 20deg;
          }
          100% {
            top: -10px;
            right: -30px;
            rotate: 0deg;
          }
        }
        .planet2{
          width: 20%;
          animation: drive3 7s infinite;
          position: absolute;
          top: -60px;
          left: 0px;
        }
        @keyframes drive3 {
          0% {
            top: -60px;
            left: 0px;
          }
          50% {
            top: -70px;
            left: -20px;
          }
          100% {
            top: -60px;
            left: 0px;
          }
        }
        .raketa2{
          width: 40%;
          animation: drive5 9s infinite;
          position: absolute;
          bottom: -200px;
          right: -100px;
          rotate: -75deg;

        }

        @keyframes drive5 {
          0% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
          10% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
          50% {
            bottom: 900px;
            right: -100px;
            rotate: -75deg;
          }
          80% {
            bottom: 900px;
            right: -500px;
            rotate: -75deg;
          }
          90% {
            bottom: -200px;
            right: -500px;
            rotate: -75deg;
          }
          100% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
        }
        .stars{
          width: 15%;
          animation: drive4 9s infinite;
          position: absolute;
          top: -10px;
          right: -10px;
          rotate: 0deg;

        }
        @keyframes drive4 {
          0% {
            rotate: 0deg;
            top: -10px;
          }
          50% {
            rotate: 180deg;
            top: -40px;
          }
          100% {
            rotate: 360deg;
            top: -10px;
          }
        }

        .planet3{
          width: 15%;
          animation: drive9 9s infinite;
          position: absolute;
          top: 5px;
          left: 5px;
          opacity: 0.6;
        }
        .stars2{
          width: 15%;
          animation: drive8 5s infinite;
          position: absolute;
          bottom: -5px;
          right: 200px;
          opacity: 0.8;
        }
        @keyframes drive9 {
          0% {
            opacity: 0.8;
          }
          50% {
            opacity: 0.1;
          }
          100% {
            opacity: 0.8;
          }
        }
        @keyframes drive8 {
          0% {
            opacity: 0.8;
          }
          50% {
            opacity: 0.1;
          }
          100% {
            opacity: 0.8;
          }
        }
        div{
          width: 80%;
          text-align: center;
        }
        .cap{
          font-size: 30pt;
          color: #410099;
          font-weight: 600;
          margin-top: 30px;
        }
        .desc{
          font-size: 16pt;
          font-weight: 600;
          margin-top: 30px;
          height: 80px;
        }
        .price{
          font-size: 30pt;
          font-weight: 600;
          margin-top: 30px;
        }
        .points{
          p{
            font-size: 16pt;
            font-weight: 600;
            margin-top: 20px;
            color: #410099;
          }
        }
        .subscript{

          margin-top: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          .btn{
            padding: 20px 40px;
            border: 1px solid #CCC;
            transition: 0.3s;
            cursor: pointer;
          }
          .btn:hover{
            background-color: #410099;
            color: #FFFFFF;
            transition: 0.3s;
          }
        }
      }

    }

  }
}
@media screen and (min-width: 600px) and (max-width: 900px){
  .main{
    width: 100%;
    height: calc(100% + 4px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -2px;
    //background-color: #410099;
    background-image: url("./planetwhite.svg");
    background-size: 100%;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 100px;
    z-index: 5;
    .slashs{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: -30px 0 30px 0;
      .sphere{
        width: 60px;
        height: 60px;
        background-color: #410099;
        border-radius: 30px;
        margin: 0 10px;
      }
      .one{
        opacity: 0.5;
        width: 40px;
        height: 40px;
        animation: sp1 1s ease-in-out infinite;
      }
      .two{
        opacity: 0.7;
        width: 50px;
        height: 50px;
        animation: sp2 1.1s ease-in-out infinite;
      }
      .tree{
        animation: sp3 1.2s ease-in-out infinite;
      }
      .four{
        opacity: 0.7;
        width: 50px;
        height: 50px;
        animation: sp2 1.1s ease-in-out infinite;
      }
      .five{
        opacity: 0.5;
        width: 40px;
        height: 40px;
        animation: sp1 1s ease-in-out infinite;
      }
      @keyframes sp1 {
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(5px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }
      @keyframes sp2{
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(7px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }
      @keyframes sp3{
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(10px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }

    }
    .nameblock{
      width: 100%;
      max-width: 1920px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px 0;
      .title{
        font-size: 30pt;
        font-family: 'ALS';
        color: #410099;
      }

    }
    .container {
      width: 100%;
      min-height: calc(100vh - 1px);
      max-width: 1920px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .blocks.visible {
        opacity: 1;
        transform: translateY(0);
      }
      .blocks{
        width: calc(100% / 2 - 20px);
        height: 800px;
        margin: 5px;
        border: 1px solid #CCC;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.5s ease, transform 0.5s ease;
        .raketa{
          width: 20%;
          animation: drive1 7s infinite;
          position: absolute;
          bottom: -100px;
          right: 160px;
          rotate: -30deg;

        }
        @keyframes drive1 {
          0% {
            bottom: -100px;
            right: 160px;
            rotate: -30deg;
          }
          50% {
            bottom: 100px;
            right: -160px;
            rotate: 30deg;
          }

          80% {
            bottom: 0px;
            right: -360px;
            rotate: 30deg;
          }
          100% {
            bottom: -100px;
            right: 160px;
            rotate: -30deg;
          }
        }
        .planet1{
          width: 20%;
          animation: drive2 7s infinite;
          position: absolute;
          top: -10px;
          right: -30px;
          rotate: 0deg;
        }
        @keyframes drive2 {
          0% {
            top: -10px;
            right: -30px;
            rotate: 0deg;
          }
          50% {
            top: 60px;
            right: -40px;
            rotate: 20deg;
          }
          100% {
            top: -10px;
            right: -30px;
            rotate: 0deg;
          }
        }
        .planet2{
          width: 20%;
          animation: drive3 7s infinite;
          position: absolute;
          top: -60px;
          left: 0px;
        }
        @keyframes drive3 {
          0% {
            top: -60px;
            left: 0px;
          }
          50% {
            top: -70px;
            left: -20px;
          }
          100% {
            top: -60px;
            left: 0px;
          }
        }
        .raketa2{
          width: 40%;
          animation: drive5 9s infinite;
          position: absolute;
          bottom: -200px;
          right: -100px;
          rotate: -75deg;

        }

        @keyframes drive5 {
          0% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
          10% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
          50% {
            bottom: 900px;
            right: -100px;
            rotate: -75deg;
          }
          80% {
            bottom: 900px;
            right: -500px;
            rotate: -75deg;
          }
          90% {
            bottom: -200px;
            right: -500px;
            rotate: -75deg;
          }
          100% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
        }
        .stars{
          width: 15%;
          animation: drive4 9s infinite;
          position: absolute;
          top: -10px;
          right: -10px;
          rotate: 0deg;

        }
        @keyframes drive4 {
          0% {
            rotate: 0deg;
            top: -10px;
          }
          50% {
            rotate: 180deg;
            top: -40px;
          }
          100% {
            rotate: 360deg;
            top: -10px;
          }
        }

        .planet3{
          width: 15%;
          animation: drive9 9s infinite;
          position: absolute;
          top: 5px;
          left: 5px;
          opacity: 0.6;
        }
        .stars2{
          width: 15%;
          animation: drive8 5s infinite;
          position: absolute;
          bottom: -5px;
          right: 200px;
          opacity: 0.8;
        }
        @keyframes drive9 {
          0% {
            opacity: 0.8;
          }
          50% {
            opacity: 0.1;
          }
          100% {
            opacity: 0.8;
          }
        }
        @keyframes drive8 {
          0% {
            opacity: 0.8;
          }
          50% {
            opacity: 0.1;
          }
          100% {
            opacity: 0.8;
          }
        }
        div{
          width: 80%;
          text-align: center;
        }
        .cap{
          font-size: 30pt;
          color: #410099;
          font-weight: 600;
          margin-top: 30px;
        }
        .desc{
          font-size: 16pt;
          font-weight: 600;
          margin-top: 30px;
          height: 80px;
        }
        .price{
          font-size: 30pt;
          font-weight: 600;
          margin-top: 30px;
        }
        .points{
          p{
            font-size: 16pt;
            font-weight: 600;
            margin-top: 12px;
            color: #410099;
            border-bottom: 1px solid #CCC;
          }
        }
        .subscript{

          margin-top: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          .btn{
            padding: 20px 40px;
            border: 1px solid #CCC;
            transition: 0.3s;
            cursor: pointer;
          }
          .btn:hover{
            background-color: #410099;
            color: #FFFFFF;
            transition: 0.3s;
          }
        }
      }

    }

  }
}
@media screen and (max-width: 600px){
  .main{
    width: 100%;
    height: calc(100% + 4px);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -2px;
    //background-color: #410099;
    background-image: url("./planetwhite.svg");
    background-size: 100%;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 100px;
    z-index: 5;
    .slashs{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: -30px 0 30px 0;
      .sphere{
        width: 40px;
        height: 40px;
        background-color: #410099;
        border-radius: 30px;
        margin: 0 10px;
      }
      .one{
        opacity: 0.5;
        width: 20px;
        height: 20px;
        animation: sp1 1s ease-in-out infinite;
      }
      .two{
        opacity: 0.7;
        width: 30px;
        height: 30px;
        animation: sp2 1.1s ease-in-out infinite;
      }
      .tree{
        animation: sp3 1.2s ease-in-out infinite;
      }
      .four{
        opacity: 0.7;
        width: 30px;
        height: 30px;
        animation: sp2 1.1s ease-in-out infinite;
      }
      .five{
        opacity: 0.5;
        width: 20px;
        height: 20px;
        animation: sp1 1s ease-in-out infinite;
      }
      @keyframes sp1 {
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(5px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }
      @keyframes sp2{
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(7px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }
      @keyframes sp3{
        0% {
          transform: translateX(0) translateY(0);
        }
        50% {
          transform: translateX(0px) translateY(10px);
        }
        100% {
          transform: translateX(0) translateY(0);
        }
      }

    }
    .nameblock{
      width: 100%;
      max-width: 1920px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px 0;
      .title{
        font-size: 30pt;
        font-family: 'ALS';
        color: #410099;
      }

    }
    .container {
      width: 100%;
      min-height: calc(100vh - 1px);
      max-width: 1920px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .blocks.visible {
        opacity: 1;
        transform: translateY(0);
      }
      .blocks{
        width: calc(100% / 1 - 20px);
        height: 600px;
        margin: 5px;
        border: 1px solid #CCC;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.5s ease, transform 0.5s ease;
        .raketa{
          width: 20%;
          animation: drive1 7s infinite;
          position: absolute;
          bottom: -100px;
          right: 160px;
          rotate: -30deg;

        }
        @keyframes drive1 {
          0% {
            bottom: -100px;
            right: 160px;
            rotate: -30deg;
          }
          50% {
            bottom: 100px;
            right: -160px;
            rotate: 30deg;
          }

          80% {
            bottom: 0px;
            right: -360px;
            rotate: 30deg;
          }
          100% {
            bottom: -100px;
            right: 160px;
            rotate: -30deg;
          }
        }
        .planet1{
          width: 20%;
          animation: drive2 7s infinite;
          position: absolute;
          top: -10px;
          right: -30px;
          rotate: 0deg;
        }
        @keyframes drive2 {
          0% {
            top: -10px;
            right: -30px;
            rotate: 0deg;
          }
          50% {
            top: 60px;
            right: -40px;
            rotate: 20deg;
          }
          100% {
            top: -10px;
            right: -30px;
            rotate: 0deg;
          }
        }
        .planet2{
          width: 20%;
          animation: drive3 7s infinite;
          position: absolute;
          top: -60px;
          left: 0px;
        }
        @keyframes drive3 {
          0% {
            top: -60px;
            left: 0px;
          }
          50% {
            top: -70px;
            left: -20px;
          }
          100% {
            top: -60px;
            left: 0px;
          }
        }
        .raketa2{
          width: 40%;
          animation: drive5 9s infinite;
          position: absolute;
          bottom: -200px;
          right: -100px;
          rotate: -75deg;

        }

        @keyframes drive5 {
          0% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
          10% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
          50% {
            bottom: 900px;
            right: -100px;
            rotate: -75deg;
          }
          80% {
            bottom: 900px;
            right: -500px;
            rotate: -75deg;
          }
          90% {
            bottom: -200px;
            right: -500px;
            rotate: -75deg;
          }
          100% {
            bottom: -200px;
            right: -100px;
            rotate: -75deg;
          }
        }
        .stars{
          width: 15%;
          animation: drive4 9s infinite;
          position: absolute;
          top: -10px;
          right: -10px;
          rotate: 0deg;

        }
        @keyframes drive4 {
          0% {
            rotate: 0deg;
            top: -10px;
          }
          50% {
            rotate: 180deg;
            top: -40px;
          }
          100% {
            rotate: 360deg;
            top: -10px;
          }
        }

        .planet3{
          width: 15%;
          animation: drive9 9s infinite;
          position: absolute;
          top: 5px;
          left: 5px;
          opacity: 0.6;
        }
        .stars2{
          width: 15%;
          animation: drive8 5s infinite;
          position: absolute;
          bottom: -5px;
          right: 200px;
          opacity: 0.8;
        }
        @keyframes drive9 {
          0% {
            opacity: 0.8;
          }
          50% {
            opacity: 0.1;
          }
          100% {
            opacity: 0.8;
          }
        }
        @keyframes drive8 {
          0% {
            opacity: 0.8;
          }
          50% {
            opacity: 0.1;
          }
          100% {
            opacity: 0.8;
          }
        }
        div{
          width: 80%;
          text-align: center;
        }
        .cap{
          font-size: 30pt;
          color: #410099;
          font-weight: 600;
          margin-top: 30px;
        }
        .desc{
          font-size: 16pt;
          font-weight: 600;
          margin-top: 10px;
          height: 80px;
        }
        .price{
          font-size: 30pt;
          font-weight: 600;
          margin-top: 0px;
        }
        .points{
          p{
            font-size: 16pt;
            font-weight: 600;
            margin-top: 12px;
            color: #410099;
            border-bottom: 1px solid #CCC;
          }
        }
        .subscript{

          margin-bottom: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          .btn{
            padding: 20px 40px;
            border: 1px solid #CCC;
            transition: 0.3s;
            cursor: pointer;
          }
          .btn:hover{
            background-color: #410099;
            color: #FFFFFF;
            transition: 0.3s;
          }
        }
      }

    }

  }
}