@media screen and (min-width: 900px){
  .main{
    width: calc(100% - 100px);
    height: 100px;
    padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.0);
    text-decoration: none;
    //margin-bottom: -100px;
    border-bottom: 1px solid #CCC;
    z-index: 1;
    .menumobile{
      display: none;
    }
    .container{
      width: 100%;
      max-width: 1920px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      .leftpart{
        img{
          width: 250px;
        }
      }
      .rightpart{
        color: #FFF;
        height: 100%;
        .burder{
          display: none;
        }
        .menu{
          display: flex;
          justify-content: flex-start;
          height: 100%;
          transition: 0.3s ease-in-out;
          .page{
            max-width: 300px;
            height: 100%;
            padding: 0 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            color: #454545;
            .text{}
            .active{
              position: absolute;
              bottom: -20px;
              left: 0px;
              width: 100%;
              height: 8px;
              background-color: #6E037B;
              display: flex;
              transition: 0.3s ease-in-out;
              opacity: 0;
            }
          }
          .page:hover{
            transition: 0.3s ease-in-out;
            .active{
              opacity: 1;
              transition: 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px){
  .main{
    width: calc(100% - 80px);
    height: 100px;
    padding: 20px 40px 20px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,1);
    text-decoration: none;
    margin-bottom: -100px;
    z-index: 9;
    border-bottom: 1px solid #CCC;
    .menumobile{
      width: 100%;
      height: calc(100vh - 141px);
      position: fixed;
      top: 141px;
      background-color: rgba(0,0,0,0.8);
      text-decoration: none;
      color: #FFFFFF;
      display: none;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      max-height: 1px;
      opacity: 0;
      margin-bottom: -50%;
      transition: 0.3s ease-in-out;
      z-index: 0;
      font-size: 0px;
      .up {

      }

      .rightpart {
        width: 100%;
        //max-height: 100vh;
        //max-height: 1080px;
        //background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 50px;
        .board{
          display: flex;
          //flex-direction: column;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          max-width: calc(1920px / 2);
          //height: 100vh;
          //max-height: 1080px;
          margin-top: -50px;
          .btn {
            width: calc(50% - 21px);
            height: 150px;
            //border-bottom: 1px solid #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            cursor: pointer;
            transition: 0.5s ease-in-out;
            position: relative;
            font-size: 20pt;
            font-weight: 200;
            //border: 1px solid #FFFFFF;
            //border-bottom: none;
            //border-top: none;
            //outline: 1px solid #FFFFFF;
            margin-left: -1px;
            text-align: center;
            color: #FFFFFF;

            .border{
              display: flex;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              //height: 1px;
              background-color: #FFFFFF;

              transition: 0.5s ease-in-out;
              color: #FFFFFF;
            }
          }
          .left{
            border-right: 1px solid #ffffff;
          }
          .down{
            border-bottom: 1px solid #fff;
          }
          .btn:hover{

            transition: 0.5s ease-in-out;

            position: relative;
            .border{
              display: flex;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #AE57FF;
              transition: 0.5s ease-in-out;
            }
          }
        }
      }
    }
    .container{
      width: 100%;
      max-width: 1920px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;
      .leftpart{
        img{
          width: 200px;
          position: absolute;
          top: 40px;
          left: 20px;
        }
      }
      .rightpart{
        color: #FFF;
        height: 100%;
        width: 100%;
        .burder{
          width: 80px;
          height: 80px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          position: absolute;
          top: 20px;
          right: 20px;
          rotate: 0deg;
          transition: 0.3s ease-in-out;
          .line{
            width: 100%;
            height: 12px;
            background-color: #410099;
            border-radius: 3px;
          }
        }
        .menu{
          display: none;
          justify-content: flex-start;
          height: 100%;
          transition: 0.3s ease-in-out;
          .page{
            max-width: 300px;
            height: 100%;
            padding: 0 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            color: #FFFFFF;
            .text{}
            .active{
              position: absolute;
              bottom: -20px;
              left: 0px;
              width: 100%;
              height: 8px;
              background-color: #6E037B;
              display: flex;
              transition: 0.3s ease-in-out;
              opacity: 0;
            }
          }
          .page:hover{
            transition: 0.3s ease-in-out;
            .active{
              opacity: 1;
              transition: 0.3s ease-in-out;
            }
          }
        }
      }
    }
  }

  @media screen and (max-height: 600px){
    .main{
      width: calc(100% - 80px);
      height: 80px;
      padding: 20px 40px 20px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255,255,255,1);
      text-decoration: none;
      margin-bottom: -100px;
      z-index: 9;
      border-bottom: 1px solid #CCC;
      .menumobile{
        width: 100%;
        height: calc(100vh - 121px);
        position: fixed;
        top: 121px;
        background-color: rgba(0,0,0,0.8);
        text-decoration: none;
        color: #FFFFFF;
        display: none;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        max-height: 1px;
        opacity: 0;
        margin-bottom: -50%;
        transition: 0.3s ease-in-out;
        z-index: 0;
        font-size: 0px;
        .up {

        }

        .rightpart {
          width: 100%;
          //max-height: 100vh;
          //max-height: 1080px;
          //background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 50px;
          .board{
            display: flex;
            //flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            max-width: calc(1920px / 2);
            //height: 100vh;
            //max-height: 1080px;
            margin-top: -50px;
            .btn {
              width: calc(50% - 21px);
              height: 90px;
              //border-bottom: 1px solid #FFFFFF;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px;
              cursor: pointer;
              transition: 0.5s ease-in-out;
              position: relative;
              font-size: 16pt;
              font-weight: 200;
              //border: 1px solid #FFFFFF;
              //border-bottom: none;
              //border-top: none;
              //outline: 1px solid #FFFFFF;
              margin-left: -1px;
              text-align: center;
              color: #FFFFFF;

              .border{
                display: flex;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                //height: 1px;
                background-color: #FFFFFF;

                transition: 0.5s ease-in-out;
                color: #FFFFFF;
              }
            }
            .left{
              border-right: 1px solid #ffffff;
            }
            .down{
              border-bottom: 1px solid #fff;
            }
            .btn:hover{

              transition: 0.5s ease-in-out;

              position: relative;
              .border{
                display: flex;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #AE57FF;
                transition: 0.5s ease-in-out;
              }
            }
          }
        }
      }
      .container{
        width: 100%;
        max-width: 1920px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        .leftpart{
          img{
            width: 200px;
            position: absolute;
            top: 40px;
            left: 20px;
          }
        }
        .rightpart{
          color: #FFF;
          height: 100%;
          width: 100%;
          .burder{
            width: 80px;
            height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            position: absolute;
            top: 20px;
            right: 20px;
            rotate: 0deg;
            transition: 0.3s ease-in-out;
            .line{
              width: 100%;
              height: 12px;
              background-color: #410099;
              border-radius: 3px;
            }
          }
          .menu{
            display: none;
            justify-content: flex-start;
            height: 100%;
            transition: 0.3s ease-in-out;
            .page{
              max-width: 300px;
              height: 100%;
              padding: 0 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              color: #FFFFFF;
              .text{}
              .active{
                position: absolute;
                bottom: -20px;
                left: 0px;
                width: 100%;
                height: 8px;
                background-color: #6E037B;
                display: flex;
                transition: 0.3s ease-in-out;
                opacity: 0;
              }
            }
            .page:hover{
              transition: 0.3s ease-in-out;
              .active{
                opacity: 1;
                transition: 0.3s ease-in-out;
              }
            }
          }
        }
      }
    }
  }
}
