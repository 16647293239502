@media screen and (min-width: 700px){
  .main{
    background-color: #FFFFFF;
    width: 90%;
    height: 90%;
    z-index: 200000;
    .container{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      .imgblock{
        width: calc(50% - 100px);
        height: calc(95% - 100px);
        margin: 50px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          height: 100%;
        }
      }
      .desc{
        width: calc(50% - 100px);
        height: calc(95% - 100px);
        margin: 50px;
        .title{
          font-size: 20pt;
          font-weight: 600;
          margin-bottom: 30px;
        }
        .description{
          font-size: 14pt;
          margin-bottom: 30px;
        }
        .category{
          padding: 15px 50px;
          border-radius: 50px;
          border: 1px solid #CCC;
          color: #CCC;
          font-size: 14pt;
          max-width: 320px;
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          cursor: pointer;
          transition: 0.3s;
        }
        .category:hover{
          border: 1px solid #454545;
          color: #454545;
          background-color: #FFF;
          transition: 0.3s;
        }
        .tocart{
          padding: 25px 50px;
          border-radius: 1px;
          border: 1px solid #CCC;
          color: #454545;
          font-size: 14pt;
          max-width: 320px;
          display: flex;
          justify-content: center;
          cursor: pointer;
          transition: 0.3s;
        }
        .tocart:hover{
          border: 1px solid #410099;
          color: #FFF;
          background-color: #410099;
          transition: 0.3s;
        }
      }
    }

  }
}
@media screen and (max-width: 700px){
  .main{
    background-color: #FFFFFF;
    width: calc(100% - 20px);
    height: 90%;
    z-index: 200000;
    .container{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding: 0;
      .imgblock{
        width: calc(100% - 10px);
        height: calc(95% - 10px);
        margin: 5px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          height: 100%;
        }
      }
      .desc{
        width: calc(100% - 20px);
        height: calc(95% - 10px);
        margin: 10px;
        padding: 0;
        .title{
          width: 100%;
          max-width: 100%;
          font-size: 16pt;
          font-weight: 600;
          margin-bottom: 20px;
          text-align: center;
        }
        .description{
          width: 100%;
          max-width: 100%;
          font-size: 12pt;
          margin-bottom: 20px;
        }
        .category{
          padding: 15px 20px;
          border-radius: 50px;
          border: 1px solid #CCC;
          color: #CCC;
          font-size: 14pt;
          //max-width: 320px;
          max-width: calc(100% - 50px);
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          cursor: pointer;
          transition: 0.3s;
        }
        .category:hover{
          border: 1px solid #454545;
          color: #454545;
          background-color: #FFF;
          transition: 0.3s;
        }
        .tocart{
          padding: 25px 50px;
          border-radius: 1px;
          border: 1px solid #CCC;
          color: #454545;
          font-size: 14pt;
          //max-width: 320px;
          max-width: calc(100% - 50px);
          display: flex;
          justify-content: center;
          cursor: pointer;
          transition: 0.3s;
          margin-bottom: 60px;
        }
        .tocart:hover{
          border: 1px solid #410099;
          color: #FFF;
          background-color: #410099;
          transition: 0.3s;
        }
      }
    }

  }
}