* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  scrollbar-width: thin;  /* Для Firefox: тонкая полоса прокрутки */
  scrollbar-color: #CCC #454545;  /* Цвет ползунка (#CCC) и трека (#454545) */
}

/* Убираем стрелочки и настраиваем стиль для WebKit-браузеров */
*::-webkit-scrollbar {
  width: 12px;  /* Ширина вертикального скролла */
  height: 12px; /* Высота горизонтального скролла */
  background: #454545; /* Фон трека */
  border-radius: 10px; /* Закругления для трека */
}

/* Настройка трека (фон полосы прокрутки) */
*::-webkit-scrollbar-track {
  background: #454545; /* Цвет фона трека */
  border-radius: 10px; /* Закругления для трека */
}

/* Настройка ползунка (thumb) */
*::-webkit-scrollbar-thumb {
  background-color: #CCC; /* Цвет ползунка */
  border-radius: 10px; /* Закругления ползунка */
  border: 2px solid #454545; /* Визуальный отступ между ползунком и треком */
}

/* Убираем стрелочки (start и end button) */
*::-webkit-scrollbar-button {
  display: none; /* Полностью скрываем стрелочки */
}

/* При наведении на ползунок */
*::-webkit-scrollbar-thumb:hover {
  background-color: #AAA; /* Более тёмный цвет при наведении */
}

@import '~@fortawesome/fontawesome-free/css/all.min.css';

div{
  /*width: 100%;*/
}

@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Regular.woff') format('woff'),
  url('./fonts/Mont-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Bold.woff') format('woff'),
  url('./fonts/Mont-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Black.woff') format('woff'),
  url('./fonts/Mont-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'ALS';
  src: url('./fonts/ALS_Tongyin_Regular.otf') format('otf'),
  url('./fonts/ALS_Tongyin_Black.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'ALS';
  src: url('./fonts/ALS_Tongyin_Black.otf') format('otf'),
  url('./fonts/ALS_Tongyin_Black.otf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

* {
  font-family: 'Mont', sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
}

.bodymain{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  text-decoration: none;
}