

@media screen and (min-width: 1500px){
  .main {
    width: 100%;
    height: 100vh;
    //max-height: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .video {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100vh;
      overflow: hidden;
      z-index: -1;
      background-image: url("./intro.jpg");
      background-size: cover;
      background-position: center center;
      //max-height: 1080px;
      .videoContent {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        //max-height: 1080px;
      }
    }

    .container {
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1000;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .leftpart {
      width: 50%;
      height: 100vh;
      //max-height: 1080px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img{
        width: calc(1920px / 2 / 100 * 60);
        margin-right: 80px;
      }
      .board{

      }
    }

    .rightpart {
      width: 50%;
      height: 100vh;
      //max-height: 1080px;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .board{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        max-width: calc(1920px / 2);
        height: 100vh;
        //max-height: 1080px;
        margin-top: -50px;
        .btn {
          width: calc(100% - 40px);
          height: 150px;
          //border-bottom: 1px solid #FFFFFF;
          color: #FFF;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 40px;
          cursor: pointer;
          transition: 0.5s ease-in-out;
          position: relative;
          font-size: 24pt;
          font-weight: 200;
          .border{
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #FFFFFF;

            transition: 0.5s ease-in-out;
          }
        }
        .btn:hover{

          transition: 0.5s ease-in-out;

          position: relative;
          .border{
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #AE57FF;
            transition: 0.5s ease-in-out;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1100px) and (max-width: 1500px){
  .main {
    width: 100%;
    height: 100vh;
    //max-height: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .video {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100vh;
      overflow: hidden;
      z-index: -1;
      background-image: url("./intro.jpg");
      background-size: cover;
      background-position: center center;
      //max-height: 1080px;
      .videoContent {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        //max-height: 1080px;
      }
    }

    .container {
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1000;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .leftpart {
      width: 50%;
      height: 100vh;
      //max-height: 1080px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img{
        width: calc(1920px / 3 / 100 * 60);
        margin-right: 80px;
      }
      .board{

      }
    }

    .rightpart {
      width: 50%;
      height: 100vh;
      //max-height: 1080px;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .board{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        max-width: calc(1920px / 2);
        height: 100vh;
        //max-height: 1080px;
        margin-top: -50px;
        .btn {
          width: calc(100% - 40px);
          height: 150px;
          //border-bottom: 1px solid #FFFFFF;
          color: #FFF;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 40px;
          cursor: pointer;
          transition: 0.5s ease-in-out;
          position: relative;
          font-size: 24pt;
          font-weight: 200;
          .border{
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #FFFFFF;

            transition: 0.5s ease-in-out;
          }
        }
        .btn:hover{

          transition: 0.5s ease-in-out;

          position: relative;
          .border{
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #AE57FF;
            transition: 0.5s ease-in-out;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 900px) and (max-width: 1100px){
  .main {
    width: 100%;
    height: 100vh;
    //max-height: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .video {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100vh;
      overflow: hidden;
      z-index: -1;
      background-image: url("./intro.jpg");
      background-size: cover;
      background-position: center center;
      //max-height: 1080px;
      .videoContent {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        //max-height: 1080px;
      }
    }

    .container {
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.2);
      z-index: 1000;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .leftpart {
      width: 50%;
      height: 100vh;
      //max-height: 1080px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img{
        width: calc(1920px / 3.5 / 100 * 60);
        margin-right: 80px;
      }
      .board{

      }
    }

    .rightpart {
      width: 50%;
      height: 100vh;
      //max-height: 1080px;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .board{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        max-width: calc(1920px / 2);
        height: 100vh;
        //max-height: 1080px;
        margin-top: -50px;
        .btn {
          width: calc(100% - 40px);
          height: 150px;
          //border-bottom: 1px solid #FFFFFF;
          color: #FFF;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 40px;
          cursor: pointer;
          transition: 0.5s ease-in-out;
          position: relative;
          font-size: 24pt;
          font-weight: 200;
          .border{
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #FFFFFF;

            transition: 0.5s ease-in-out;
          }
        }
        .btn:hover{

          transition: 0.5s ease-in-out;

          position: relative;
          .border{
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #AE57FF;
            transition: 0.5s ease-in-out;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px){
  .main {
    width: 100%;
    height: 100vh;
    //max-height: 1080px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    .video {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100vh;
      overflow: hidden;
      z-index: -1;
      background-image: url("./intro.jpg");
      background-size: cover;
      background-position: center center;
      //max-height: 1080px;
      .videoContent {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        //max-height: 1080px;
      }
    }

    .container {
      width: 100%;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 1000;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .leftpart {
      width: 100%;
      //height: 100vh;
      //max-height: 1080px;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: calc(1920px / 2 / 100 * 30);
        margin-right: 0px;
        padding-left: 0px;
        margin-top: 20px;
      }
      .board{

      }
    }

    .rightpart {
      width: 100%;
      //max-height: 100vh;
      //max-height: 1080px;
      //background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .board{
        display: flex;
        //flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        max-width: calc(1920px / 2);
        //height: 100vh;
        //max-height: 1080px;
        margin-top: -50px;
        .btn {
          width: calc(50% - 21px);
          height: 150px;
          //border-bottom: 1px solid #FFFFFF;
          color: #FFF;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px;
          cursor: pointer;
          transition: 0.5s ease-in-out;
          position: relative;
          font-size: 20pt;
          font-weight: 200;
          border: 1px solid #FFFFFF;
          border-bottom: none;
          border-top: none;
          //outline: 1px solid #FFFFFF;
          margin-left: -1px;
          text-align: center;

          .border{
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #FFFFFF;

            transition: 0.5s ease-in-out;
          }
        }
        .btn:hover{

          transition: 0.5s ease-in-out;

          position: relative;
          .border{
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #AE57FF;
            transition: 0.5s ease-in-out;
          }
        }
      }
    }
  }
  @media screen and (min-height: 400px) and (max-height: 600px){
    .main {
      width: 100%;
      height: 100vh;
      //max-height: 1080px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .video {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100vh;
        overflow: hidden;
        z-index: -1;
        background-image: url("./intro.jpg");
        background-size: cover;
        background-position: center center;
        //max-height: 1080px;
        .videoContent {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          //max-height: 1080px;
        }
      }

      .container {
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      .leftpart {
        width: 100%;
        //height: 100vh;
        //max-height: 1080px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: calc(1920px / 5 / 100 * 30);
          margin-right: 0px;
          padding-left: 0px;
          margin-top: 20px;
        }
        .board{

        }
      }

      .rightpart {
        width: 100%;
        //max-height: 100vh;
        //max-height: 1080px;
        //background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .board{
          display: flex;
          //flex-direction: column;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          max-width: calc(1920px / 2);
          //height: 100vh;
          //max-height: 1080px;
          margin-top: -50px;
          .btn {
            width: calc(50% - 21px);
            height: 90px;
            //border-bottom: 1px solid #FFFFFF;
            color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            cursor: pointer;
            transition: 0.5s ease-in-out;
            position: relative;
            font-size: 16pt;
            font-weight: 200;
            border: 1px solid #FFFFFF;
            border-bottom: none;
            border-top: none;
            //outline: 1px solid #FFFFFF;
            margin-left: -1px;
            text-align: center;

            .border{
              display: flex;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #FFFFFF;

              transition: 0.5s ease-in-out;
            }
          }
          .btn:hover{

            transition: 0.5s ease-in-out;

            position: relative;
            .border{
              display: flex;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #AE57FF;
              transition: 0.5s ease-in-out;
            }
          }
        }
      }
    }

  }
  @media screen and (max-height: 400px){
    .main {
      width: 100%;
      height: 100vh;
      //max-height: 1080px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      .video {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100vh;
        overflow: hidden;
        z-index: -1;
        background-image: url("./intro.jpg");
        background-size: cover;
        background-position: center center;
        //max-height: 1080px;
        .videoContent {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          //max-height: 1080px;
        }
      }

      .container {
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      .leftpart {
        width: 100%;
        //height: 100vh;
        //max-height: 1080px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: calc(1920px / 5 / 100 * 30);
          margin-right: 0px;
          padding-left: 0px;
          margin-top: 20px;
        }
        .board{

        }
      }

      .rightpart {
        width: 100%;
        //max-height: 100vh;
        //max-height: 1080px;
        //background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .board{
          display: flex;
          //flex-direction: column;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: flex-start;
          width: 100%;
          max-width: calc(1920px / 2);
          //height: 100vh;
          //max-height: 1080px;
          margin-top: -50px;
          .btn {
            width: calc(50% - 21px);
            height: 80px;
            //border-bottom: 1px solid #FFFFFF;
            color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            cursor: pointer;
            transition: 0.5s ease-in-out;
            position: relative;
            font-size: 16pt;
            font-weight: 200;
            border: 1px solid #FFFFFF;
            border-bottom: none;
            border-top: none;
            //outline: 1px solid #FFFFFF;
            margin-left: -1px;
            text-align: center;

            .border{
              display: flex;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #FFFFFF;

              transition: 0.5s ease-in-out;
            }
          }
          .btn:hover{

            transition: 0.5s ease-in-out;

            position: relative;
            .border{
              display: flex;
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #AE57FF;
              transition: 0.5s ease-in-out;
            }
          }
        }
      }
    }

  }

}
