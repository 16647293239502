@media screen and (min-width: 1600px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    //padding-top: 100px;

    .menu{
      width: 100%;
      height: 80px;
      border-bottom: 4px solid #CCC;
      display: flex;
      justify-content: center;
      .containermenu{
        width: 100%;
        max-width: calc(1920px - 80px);
        display: flex;
        justify-content: center;
        padding: 0 40px;
        min-height: 80px;
        .itemmenu{
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          //outline: 1px solid #8f4899;
          padding: 0 30px;
          cursor: pointer;
          transition: 0.3s;
        }
        .itemmenu:hover{
          background-color: #E1E1E1;
          transition: 0.3s;
        }
      }
    }
    .containername{
      width: 100%;
      min-height: 80px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .category{
        width: calc(100% - 80px);
        max-width: calc(1919px - 80px);
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 30pt;

        .moreitems{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14pt;
          height: 60px;
          padding: 0 30px;
          border-radius: 50px;
          border: 1px solid #CCC;
          margin-left: 30px;
          cursor: pointer;
          transition: 0.3s;
        }
        .moreitems:hover{
          background-color: #410099;
          color: #FFFFFF;
          border: 1px solid #410099;
          transition: 0.3s;
        }
      }
    }

    .container{
      width: 100%;
      //min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./reacloud.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{}
      .contenttwo{
        width: 100%;
        //min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .products{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .block{
            width: calc(100% / 5 - 22px);
            height: 500px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            margin: 10px;
            background-color: #FFFFFF;
            align-items: center;
            justify-content: space-between;
            .aplicate{
              width: 100%;
              height: 60%;
              background-size: cover;
              background-position: center center;
            }
            .name{
              font-size: 20pt;
              margin: 10px;
            }
            .category{
              font-size: 14pt;
              padding: 10px 40px;
              border-radius: 30px;
              border: 1px solid #CCC;
              margin: 10px;
            }
            .btncart{
              font-size: 14pt;
              border-top: 1px solid #CCC;
              width: 100%;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: 0.3s;
            }
            .btncart:hover{

              border-top: 1px solid #410099;
              background-color: #410099;
              color: #FFF;
              transition: 0.3s;
            }
          }


        }
      }
      .moreblock{
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .more{
          width: calc(100% - 2px);
          height: 120px;
          border: 1px solid #454545;
          margin-top: 30px;
          margin-bottom: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 20px;
          cursor: pointer;
          transition: .3s;
        }
        .more:hover{
          color: #FFFFFF;
          background-color: #410099;
          transition: .2s;
        }
      }
    }
    @media (min-width: 1920px) {
      .container {
        background-size: 1920px auto;
        background-repeat: no-repeat;
      }
    }
    @media (max-width: 1200px) {
      .container {
        width: 100%;
        min-height: 100vh;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("./reacloud.svg");
        background-size: 100%;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        .content{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .left{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30%;
              animation: moveLeftRight 3s ease-in-out infinite;
              margin-left: 50px;
            }
            @keyframes moveLeftRight {
              0% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
              50% {
                transform: translateX(10px) translateY(10px) rotate(2deg);
              }
              100% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
            }
          }

          .right{
            width: calc(100% - 120px);
            padding: 60px 60px 60px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -150px;
            z-index: 1;
            .title{
              font-family: 'ALS';
              font-size: 60pt;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: #410099;
            }
            .pretitle{
              font-size: 35pt;
              text-transform: uppercase;
              color: #595959;
              text-align: center;
            }
            .desc{

              font-size: 20pt;
              color: #454545;
              text-align: justify;
            }
          }
        }
        .contenttwo{
          width: 100%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    //padding-top: 100px;

    .menu{
      width: 100%;
      height: 80px;
      border-bottom: 4px solid #CCC;
      display: flex;
      justify-content: center;
      .containermenu{
        width: 100%;
        max-width: calc(1920px - 80px);
        display: flex;
        justify-content: center;
        padding: 0 40px;
        min-height: 80px;
        .itemmenu{
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          //outline: 1px solid #8f4899;
          padding: 0 30px;
          cursor: pointer;
          transition: 0.3s;
        }
        .itemmenu:hover{
          background-color: #E1E1E1;
          transition: 0.3s;
        }
      }
    }
    .containername{
      width: 100%;
      min-height: 80px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .category{
        width: calc(100% - 80px);
        max-width: calc(1919px - 80px);
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 30pt;

        .moreitems{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14pt;
          height: 60px;
          padding: 0 30px;
          border-radius: 50px;
          border: 1px solid #CCC;
          margin-left: 30px;
          cursor: pointer;
          transition: 0.3s;
        }
        .moreitems:hover{
          background-color: #410099;
          color: #FFFFFF;
          border: 1px solid #410099;
          transition: 0.3s;
        }
      }
    }

    .container{
      width: 100%;
      //min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./reacloud.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{}
      .contenttwo{
        width: 100%;
        //min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .products{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .block{
            width: calc(100% / 4 - 22px);
            height: 500px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            margin: 10px;
            background-color: #FFFFFF;
            align-items: center;
            justify-content: space-between;
            .aplicate{
              width: 100%;
              height: 60%;
              background-size: cover;
              background-position: center center;
            }
            .name{
              font-size: 20pt;
              margin: 10px;
            }
            .category{
              font-size: 14pt;
              padding: 10px 40px;
              border-radius: 30px;
              border: 1px solid #CCC;
              margin: 10px;
            }
            .btncart{
              font-size: 14pt;
              border-top: 1px solid #CCC;
              width: 100%;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: 0.3s;
            }
            .btncart:hover{

              border-top: 1px solid #410099;
              background-color: #410099;
              color: #FFF;
              transition: 0.3s;
            }
          }


        }
      }
      .moreblock{
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .more{
          width: calc(100% - 2px);
          height: 120px;
          border: 1px solid #454545;
          margin-top: 30px;
          margin-bottom: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 20px;
          cursor: pointer;
          transition: .3s;
        }
        .more:hover{
          color: #FFFFFF;
          background-color: #410099;
          transition: .2s;
        }
      }
    }
    @media (min-width: 1920px) {
      .container {
        background-size: 1920px auto;
        background-repeat: no-repeat;
      }
    }
    @media (max-width: 1200px) {
      .container {
        width: 100%;
        min-height: 100vh;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("./reacloud.svg");
        background-size: 100%;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        .content{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .left{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30%;
              animation: moveLeftRight 3s ease-in-out infinite;
              margin-left: 50px;
            }
            @keyframes moveLeftRight {
              0% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
              50% {
                transform: translateX(10px) translateY(10px) rotate(2deg);
              }
              100% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
            }
          }

          .right{
            width: calc(100% - 120px);
            padding: 60px 60px 60px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -150px;
            z-index: 1;
            .title{
              font-family: 'ALS';
              font-size: 60pt;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: #410099;
            }
            .pretitle{
              font-size: 35pt;
              text-transform: uppercase;
              color: #595959;
              text-align: center;
            }
            .desc{

              font-size: 20pt;
              color: #454545;
              text-align: justify;
            }
          }
        }
        .contenttwo{
          width: 100%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }
  }
}
@media screen and (min-width: 650px) and (max-width: 1200px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    //padding-top: 100px;

    .menu{
      width: 100%;
      height: 80px;
      border-bottom: 4px solid #CCC;
      display: none;
      justify-content: center;
      .containermenu{
        width: 100%;
        max-width: calc(1920px - 80px);
        display: flex;
        justify-content: center;
        padding: 0 40px;
        min-height: 80px;
        .itemmenu{
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          //outline: 1px solid #8f4899;
          padding: 0 30px;
          cursor: pointer;
          transition: 0.3s;
        }
        .itemmenu:hover{
          background-color: #E1E1E1;
          transition: 0.3s;
        }
      }
    }
    .containername{
      width: 100%;
      min-height: 80px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .category{
        width: calc(100% - 80px);
        max-width: calc(1919px - 80px);
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 30pt;

        .moreitems{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14pt;
          height: 60px;
          padding: 0 30px;
          border-radius: 50px;
          border: 1px solid #CCC;
          margin-left: 30px;
          cursor: pointer;
          transition: 0.3s;
        }
        .moreitems:hover{
          background-color: #410099;
          color: #FFFFFF;
          border: 1px solid #410099;
          transition: 0.3s;
        }
      }
    }

    .container{
      width: 100%;
      //min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./reacloud.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{}
      .contenttwo{
        width: 100%;
        //min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .products{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .block{
            width: calc(100% / 2 - 22px);
            height: 500px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            margin: 10px;
            background-color: #FFFFFF;
            align-items: center;
            justify-content: space-between;
            .aplicate{
              width: 100%;
              height: 60%;
              background-size: cover;
              background-position: center center;
            }
            .name{
              font-size: 20pt;
              margin: 10px;
            }
            .category{
              font-size: 14pt;
              padding: 10px 40px;
              border-radius: 30px;
              border: 1px solid #CCC;
              margin: 10px;
            }
            .btncart{
              font-size: 14pt;
              border-top: 1px solid #CCC;
              width: 100%;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: 0.3s;
            }
            .btncart:hover{

              border-top: 1px solid #410099;
              background-color: #410099;
              color: #FFF;
              transition: 0.3s;
            }
          }


        }
      }
      .moreblock{
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .more{
          width: calc(100% - 2px);
          height: 120px;
          border: 1px solid #454545;
          margin-top: 30px;
          margin-bottom: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 20px;
          cursor: pointer;
          transition: .3s;
        }
        .more:hover{
          color: #FFFFFF;
          background-color: #410099;
          transition: .2s;
        }
      }
    }
    @media (min-width: 1920px) {
      .container {
        background-size: 1920px auto;
        background-repeat: no-repeat;
      }
    }
    @media (max-width: 1200px) {
      .container {
        width: 100%;
        min-height: 100vh;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("./reacloud.svg");
        background-size: 100%;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        .content{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .left{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30%;
              animation: moveLeftRight 3s ease-in-out infinite;
              margin-left: 50px;
            }
            @keyframes moveLeftRight {
              0% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
              50% {
                transform: translateX(10px) translateY(10px) rotate(2deg);
              }
              100% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
            }
          }

          .right{
            width: calc(100% - 120px);
            padding: 60px 60px 60px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -150px;
            z-index: 1;
            .title{
              font-family: 'ALS';
              font-size: 60pt;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: #410099;
            }
            .pretitle{
              font-size: 35pt;
              text-transform: uppercase;
              color: #595959;
              text-align: center;
            }
            .desc{

              font-size: 20pt;
              color: #454545;
              text-align: justify;
            }
          }
        }
        .contenttwo{
          width: 100%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }
  }
}
@media screen and (max-width: 650px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    //padding-top: 100px;

    .menu{
      width: 100%;
      height: 80px;
      border-bottom: 4px solid #CCC;
      display: none;
      justify-content: center;
      .containermenu{
        width: 100%;
        max-width: calc(1920px - 80px);
        display: flex;
        justify-content: center;
        padding: 0 40px;
        min-height: 80px;
        .itemmenu{
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          //outline: 1px solid #8f4899;
          padding: 0 30px;
          cursor: pointer;
          transition: 0.3s;
        }
        .itemmenu:hover{
          background-color: #E1E1E1;
          transition: 0.3s;
        }
      }
    }
    .containername{
      width: 100%;
      min-height: 80px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .category{
        width: calc(100% - 80px);
        max-width: calc(1919px - 80px);
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 30pt;

        .moreitems{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          font-size: 14pt;
          height: 60px;
          padding: 0 30px;
          border-radius: 50px;
          border: 1px solid #CCC;
          margin-left: 30px;
          cursor: pointer;
          transition: 0.3s;
        }
        .moreitems:hover{
          background-color: #410099;
          color: #FFFFFF;
          border: 1px solid #410099;
          transition: 0.3s;
        }
      }
    }

    .container{
      width: 100%;
      //min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./reacloud.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{}
      .contenttwo{
        width: 100%;
        //min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .products{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          .block{
            width: calc(100% / 1 - 22px);
            height: 500px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            margin: 10px;
            background-color: #FFFFFF;
            align-items: center;
            justify-content: space-between;
            .aplicate{
              width: 100%;
              height: 60%;
              background-size: cover;
              background-position: center center;
            }
            .name{
              font-size: 20pt;
              margin: 10px;
            }
            .category{
              font-size: 14pt;
              padding: 10px 40px;
              border-radius: 30px;
              border: 1px solid #CCC;
              margin: 10px;
            }
            .btncart{
              font-size: 14pt;
              border-top: 1px solid #CCC;
              width: 100%;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: 0.3s;
            }
            .btncart:hover{

              border-top: 1px solid #410099;
              background-color: #410099;
              color: #FFF;
              transition: 0.3s;
            }
          }


        }
      }
      .moreblock{
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .more{
          width: calc(100% - 2px);
          height: 120px;
          border: 1px solid #454545;
          margin-top: 30px;
          margin-bottom: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 400;
          font-size: 20px;
          cursor: pointer;
          transition: .3s;
        }
        .more:hover{
          color: #FFFFFF;
          background-color: #410099;
          transition: .2s;
        }
      }
    }
    @media (min-width: 1920px) {
      .container {
        background-size: 1920px auto;
        background-repeat: no-repeat;
      }
    }
    @media (max-width: 1200px) {
      .container {
        width: 100%;
        min-height: 100vh;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("./reacloud.svg");
        background-size: 100%;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        .content{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .left{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30%;
              animation: moveLeftRight 3s ease-in-out infinite;
              margin-left: 50px;
            }
            @keyframes moveLeftRight {
              0% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
              50% {
                transform: translateX(10px) translateY(10px) rotate(2deg);
              }
              100% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
            }
          }

          .right{
            width: calc(100% - 120px);
            padding: 60px 60px 60px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -150px;
            z-index: 1;
            .title{
              font-family: 'ALS';
              font-size: 60pt;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: #410099;
            }
            .pretitle{
              font-size: 35pt;
              text-transform: uppercase;
              color: #595959;
              text-align: center;
            }
            .desc{

              font-size: 20pt;
              color: #454545;
              text-align: justify;
            }
          }
        }
        .contenttwo{
          width: 100%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }
  }
}