@media screen and (min-width: 1600px){
  .block{
    width: calc(100% / 5 - 22px);
    height: 500px;
    border: 1px solid #CCC;
    display: flex;
    flex-direction: column;
    margin: 10px;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: space-between;
    &:hover{
      box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
    }
    .aplicate{
      width: 100%;
      height: 60%;
      background-size: cover;
      background-position: center center;
    }
    .name{
      font-size: 16pt;
      margin: 10px;
    }
    .category{
      font-size: 12pt;
      padding: 10px 40px;
      border-radius: 30px;
      border: 1px solid #CCC;
      margin: 10px;
    }
    .btncart{
      font-size: 14pt;
      border-top: 1px solid #CCC;
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
    }
    .btncart:hover{

      border-top: 1px solid #410099;
      background-color: #410099;
      color: #FFF;
      transition: 0.3s;
    }
  }

}
@media screen and (min-width: 1200px) and (max-width: 1600px){
  .block{
    width: calc(100% / 4 - 22px);
    height: 500px;
    border: 1px solid #CCC;
    display: flex;
    flex-direction: column;
    margin: 10px;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: space-between;
    .aplicate{
      width: 100%;
      height: 60%;
      background-size: cover;
      background-position: center center;
    }
    .name{
      font-size: 16pt;
      margin: 10px;
    }
    .category{
      font-size: 12pt;
      padding: 10px 40px;
      border-radius: 30px;
      border: 1px solid #CCC;
      margin: 10px;
    }
    .btncart{
      font-size: 14pt;
      border-top: 1px solid #CCC;
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
    }
    .btncart:hover{

      border-top: 1px solid #410099;
      background-color: #410099;
      color: #FFF;
      transition: 0.3s;
    }
  }
}
@media screen and (min-width: 650px) and (max-width: 1200px){
  .block{
    width: calc(100% / 2 - 22px);
    height: 500px;
    border: 1px solid #CCC;
    display: flex;
    flex-direction: column;
    margin: 10px;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: space-between;
    .aplicate{
      width: 100%;
      height: 60%;
      background-size: cover;
      background-position: center center;
    }
    .name{
      font-size: 16pt;
      margin: 10px;
    }
    .category{
      font-size: 12pt;
      padding: 10px 40px;
      border-radius: 30px;
      border: 1px solid #CCC;
      margin: 10px;
    }
    .btncart{
      font-size: 14pt;
      border-top: 1px solid #CCC;
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
    }
    .btncart:hover{

      border-top: 1px solid #410099;
      background-color: #410099;
      color: #FFF;
      transition: 0.3s;
    }
  }
  @media screen and (max-height: 600px){
    .block{
      width: calc(100% / 2 - 22px);
      height: 500px;
      max-height: 90vh;
      border: 1px solid #CCC;
      display: flex;
      flex-direction: column;
      margin: 10px;
      background-color: #FFFFFF;
      align-items: center;
      justify-content: space-between;
      .aplicate{
        width: 100%;
        height: 60%;
        background-size: cover;
        background-position: center center;
      }
      .name{
        font-size: 16pt;
        margin: 10px;
      }
      .category{
        font-size: 12pt;
        padding: 10px 40px;
        border-radius: 30px;
        border: 1px solid #CCC;
        margin: 10px;
      }
      .btncart{
        font-size: 14pt;
        border-top: 1px solid #CCC;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.3s;
      }
      .btncart:hover{

        border-top: 1px solid #410099;
        background-color: #410099;
        color: #FFF;
        transition: 0.3s;
      }
    }
  }
}
@media screen and (max-width: 650px){
  .block{
    width: calc(100% / 1 - 22px);
    height: 500px;
    border: 1px solid #CCC;
    display: flex;
    flex-direction: column;
    margin: 10px;
    background-color: #FFFFFF;
    align-items: center;
    justify-content: space-between;
    .aplicate{
      width: 100%;
      height: 60%;
      background-size: cover;
      background-position: center center;
    }
    .name{
      font-size: 16pt;
      margin: 10px;
    }
    .category{
      font-size: 12pt;
      padding: 10px 40px;
      border-radius: 30px;
      border: 1px solid #CCC;
      margin: 10px;
    }
    .btncart{
      font-size: 14pt;
      border-top: 1px solid #CCC;
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
    }
    .btncart:hover{

      border-top: 1px solid #410099;
      background-color: #410099;
      color: #FFF;
      transition: 0.3s;
    }
  }
}