@media screen and (min-width: 1600px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    //padding-top: 100px;

    .container{
      width: 100%;
      min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./reacloud.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          width: 50%;
          height: 500px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .img1{
            animation: i1 3s ease-in-out infinite;
          }
          .img2{
            animation: i2 3s ease-in-out infinite;
          }
          .img3{
            animation: i3 3s ease-in-out infinite;
          }
          .img4{
            animation: i4 3s ease-in-out infinite;
          }
          img{
            height: 100%;
            position: absolute;
            top: 0;
          }
          @keyframes i1 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(10px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          @keyframes i2 {
            0% {
              transform: translateX(10px) translateY(10px) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(0px) rotate(2deg);
            }
            100% {
              transform: translateX(10px) translateY(10px) rotate(0deg);
            }
          }
          @keyframes i3 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(0px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          @keyframes i4 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(10px) rotate(2deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
        }

        .right{
          width: calc(50% - 60px);
          padding: 60px 60px 60px 0;
          .title{
            font-family: 'ALS';
            font-size: 60pt;
            text-transform: uppercase;
            margin-bottom: 100px;
            color: #410099;
          }
          .pretitle{
            font-size: 40pt;
            text-transform: uppercase;
            color: #595959;
          }
          .desc{

            font-size: 20pt;
            color: #454545;
          }
        }
      }
      .contenttwo{
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .title{
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .nameblock{
            font-size: 60pt;
            font-weight: 600;
            color: darkgreen;
            margin-left: 40px;
          }
          .relocation{
            font-size: 16pt;
            border: 1px solid #CCC;
            padding: 20px 40px;
            border-radius: 50px;
            margin-left: 20px;
            margin-top: -5px;
            cursor: pointer;
            transition: 0.3s;
          }
          .relocation:hover{
            border-top: 1px solid #410099;
            background-color: #410099;
            color: #FFF;
            transition: 0.3s;
          }

        }
        .products{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .block{
            width: calc(100% / 5 - 22px);
            height: 500px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            margin: 10px;
            background-color: #FFFFFF;
            align-items: center;
            justify-content: space-between;
            .aplicate{
              width: 100%;
              height: 60%;
              background-size: cover;
              background-position: center center;
            }
            .name{
              font-size: 14pt;
              margin: 10px;
            }
            .category{
              font-size: 12pt;
              padding: 10px 40px;
              border-radius: 30px;
              border: 1px solid #CCC;
              margin: 10px;
            }
            .btncart{
              font-size: 14pt;
              border-top: 1px solid #CCC;
              width: 100%;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: 0.3s;
            }
            .btncart:hover{

              border-top: 1px solid #410099;
              background-color: #410099;
              color: #FFF;
              transition: 0.3s;
            }
          }


        }
      }
      .moreblock{
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .more{
          min-width: 320px;
          width: calc(100% / 5 - 22px);
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FFFFFF;
          border: 1px solid #CCC;
          margin: 0 10px 50px 10px;
          font-size: 20pt;
          cursor: pointer;
          transition: 0.3s;
          color: #454545;
        }
        .more:hover{
          border-top: 1px solid #410099;
          background-color: #410099;
          color: #FFF;
          transition: 0.3s;
        }
      }
    }
    @media (min-width: 1920px) {
      .container {
        background-size: 1920px auto;
        background-repeat: no-repeat;
      }
    }
    @media (max-width: 1200px) {
      .container {
        width: 100%;
        min-height: 100vh;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("./reacloud.svg");
        background-size: 100%;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        .content{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .left{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30%;
              animation: moveLeftRight 3s ease-in-out infinite;
              margin-left: 50px;
            }
            @keyframes moveLeftRight {
              0% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
              50% {
                transform: translateX(10px) translateY(10px) rotate(2deg);
              }
              100% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
            }
          }

          .right{
            width: calc(100% - 120px);
            padding: 60px 60px 60px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -150px;
            z-index: 1;
            .title{
              font-family: 'ALS';
              font-size: 60pt;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: #410099;
            }
            .pretitle{
              font-size: 35pt;
              text-transform: uppercase;
              color: #595959;
              text-align: center;
            }
            .desc{

              font-size: 20pt;
              color: #454545;
              text-align: justify;
            }
          }
        }
        .contenttwo{
          width: 100%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1600px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    //padding-top: 100px;

    .container{
      width: 100%;
      min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./reacloud.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          width: 50%;
          height: 500px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .img1{
            animation: i1 3s ease-in-out infinite;
          }
          .img2{
            animation: i2 3s ease-in-out infinite;
          }
          .img3{
            animation: i3 3s ease-in-out infinite;
          }
          .img4{
            animation: i4 3s ease-in-out infinite;
          }
          img{
            height: 100%;
            position: absolute;
            top: 0;
          }
          @keyframes i1 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(10px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          @keyframes i2 {
            0% {
              transform: translateX(10px) translateY(10px) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(0px) rotate(2deg);
            }
            100% {
              transform: translateX(10px) translateY(10px) rotate(0deg);
            }
          }
          @keyframes i3 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(0px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          @keyframes i4 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(10px) rotate(2deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
        }

        .right{
          width: calc(50% - 60px);
          padding: 60px 60px 60px 0;
          .title{
            font-family: 'ALS';
            font-size: 60pt;
            text-transform: uppercase;
            margin-bottom: 100px;
            color: #410099;
          }
          .pretitle{
            font-size: 40pt;
            text-transform: uppercase;
            color: #595959;
          }
          .desc{

            font-size: 20pt;
            color: #454545;
          }
        }
      }
      .contenttwo{
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .title{
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .nameblock{
            font-size: 60pt;
            font-weight: 600;
            color: darkgreen;
            margin-left: 40px;
          }
          .relocation{
            font-size: 16pt;
            border: 1px solid #CCC;
            padding: 20px 40px;
            border-radius: 50px;
            margin-left: 20px;
            margin-top: -5px;
            cursor: pointer;
            transition: 0.3s;
          }
          .relocation:hover{
            border-top: 1px solid #410099;
            background-color: #410099;
            color: #FFF;
            transition: 0.3s;
          }

        }
        .products{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .block{
            width: calc(100% / 5 - 22px);
            height: 500px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            margin: 10px;
            background-color: #FFFFFF;
            align-items: center;
            justify-content: space-between;
            .aplicate{
              width: 100%;
              height: 60%;
              background-size: cover;
              background-position: center center;
            }
            .name{
              font-size: 14pt;
              margin: 10px;
            }
            .category{
              font-size: 12pt;
              padding: 10px 40px;
              border-radius: 30px;
              border: 1px solid #CCC;
              margin: 10px;
            }
            .btncart{
              font-size: 14pt;
              border-top: 1px solid #CCC;
              width: 100%;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: 0.3s;
            }
            .btncart:hover{

              border-top: 1px solid #410099;
              background-color: #410099;
              color: #FFF;
              transition: 0.3s;
            }
          }


        }
      }
      .moreblock{
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .more{
          min-width: 320px;
          width: calc(100% / 5 - 22px);
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FFFFFF;
          border: 1px solid #CCC;
          margin: 0 10px 50px 10px;
          font-size: 20pt;
          cursor: pointer;
          transition: 0.3s;
          color: #454545;
        }
        .more:hover{
          border-top: 1px solid #410099;
          background-color: #410099;
          color: #FFF;
          transition: 0.3s;
        }
      }
    }
    @media (min-width: 1920px) {
      .container {
        background-size: 1920px auto;
        background-repeat: no-repeat;
      }
    }
    @media (max-width: 1200px) {
      .container {
        width: 100%;
        min-height: 100vh;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("./reacloud.svg");
        background-size: 100%;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        .content{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .left{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30%;
              animation: moveLeftRight 3s ease-in-out infinite;
              margin-left: 50px;
            }
            @keyframes moveLeftRight {
              0% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
              50% {
                transform: translateX(10px) translateY(10px) rotate(2deg);
              }
              100% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
            }
          }

          .right{
            width: calc(100% - 120px);
            padding: 60px 60px 60px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -150px;
            z-index: 1;
            .title{
              font-family: 'ALS';
              font-size: 60pt;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: #410099;
            }
            .pretitle{
              font-size: 35pt;
              text-transform: uppercase;
              color: #595959;
              text-align: center;
            }
            .desc{

              font-size: 20pt;
              color: #454545;
              text-align: justify;
            }
          }
        }
        .contenttwo{
          width: 100%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }
  }
}
@media screen and (min-width: 650px) and (max-width: 1200px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    //padding-top: 100px;

    .container{
      width: 100%;
      min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./reacloud.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          width: 100%;
          height: 500px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          .img1{
            animation: i1 3s ease-in-out infinite;
          }
          .img2{
            animation: i2 3s ease-in-out infinite;
          }
          .img3{
            animation: i3 3s ease-in-out infinite;
          }
          .img4{
            animation: i4 3s ease-in-out infinite;
          }
          img{
            //height: 100%;
            //min-height: 100%;
            width: 100%;
            min-width: 500px;
            //min-width: 100%;
            position: absolute;
            top: 0;
          }
          @keyframes i1 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(10px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          @keyframes i2 {
            0% {
              transform: translateX(10px) translateY(10px) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(0px) rotate(2deg);
            }
            100% {
              transform: translateX(10px) translateY(10px) rotate(0deg);
            }
          }
          @keyframes i3 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(0px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          @keyframes i4 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(10px) rotate(2deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
        }

        .right{
          width: calc(50% - 60px);
          padding: 60px 60px 60px 0;
          .title{
            font-family: 'ALS';
            font-size: 60pt;
            text-transform: uppercase;
            margin-bottom: 100px;
            color: #410099;
          }
          .pretitle{
            font-size: 40pt;
            text-transform: uppercase;
            color: #595959;
          }
          .desc{

            font-size: 20pt;
            color: #454545;
          }
        }
      }
      .contenttwo{
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .title{
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .nameblock{
            font-size: 60pt;
            font-weight: 600;
            color: darkgreen;
            margin-left: 40px;
          }
          .relocation{
            font-size: 16pt;
            border: 1px solid #CCC;
            padding: 20px 40px;
            border-radius: 50px;
            margin-left: 20px;
            margin-top: -5px;
            cursor: pointer;
            transition: 0.3s;
          }
          .relocation:hover{
            border-top: 1px solid #410099;
            background-color: #410099;
            color: #FFF;
            transition: 0.3s;
          }

        }
        .products{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .block{
            width: calc(100% / 4 - 22px);
            height: 500px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            margin: 10px;
            background-color: #FFFFFF;
            align-items: center;
            justify-content: space-between;
            .aplicate{
              width: 100%;
              height: 60%;
              max-height: 220px;
              background-size: cover;
              background-position: center center;
            }
            .name{
              font-size: 14pt;
              margin: 10px;
            }
            .category{
              font-size: 12pt;
              padding: 10px 40px;
              border-radius: 30px;
              border: 1px solid #CCC;
              margin: 10px;
            }
            .btncart{
              font-size: 14pt;
              border-top: 1px solid #CCC;
              width: 100%;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: 0.3s;
            }
            .btncart:hover{

              border-top: 1px solid #410099;
              background-color: #410099;
              color: #FFF;
              transition: 0.3s;
            }
          }


        }
      }
      .moreblock{
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .more{
          min-width: 320px;
          width: calc(100% / 5 - 22px);
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FFFFFF;
          border: 1px solid #CCC;
          margin: 0 10px 50px 10px;
          font-size: 20pt;
          cursor: pointer;
          transition: 0.3s;
          color: #454545;
        }
        .more:hover{
          border-top: 1px solid #410099;
          background-color: #410099;
          color: #FFF;
          transition: 0.3s;
        }
      }
    }
    @media (min-width: 1920px) {
      .container {
        background-size: 1920px auto;
        background-repeat: no-repeat;
      }
    }
    @media (max-width: 1200px) {
      .container {
        width: 100%;
        min-height: 100vh;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("./reacloud.svg");
        background-size: 100%;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        .content{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .left{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30%;
              animation: moveLeftRight 3s ease-in-out infinite;
              margin-left: 50px;
            }
            @keyframes moveLeftRight {
              0% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
              50% {
                transform: translateX(10px) translateY(10px) rotate(2deg);
              }
              100% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
            }
          }

          .right{
            width: calc(100% - 120px);
            padding: 60px 60px 60px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -150px;
            z-index: 1;
            .title{
              font-family: 'ALS';
              font-size: 60pt;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: #410099;
            }
            .pretitle{
              font-size: 35pt;
              text-transform: uppercase;
              color: #595959;
              text-align: center;
            }
            .desc{

              font-size: 20pt;
              color: #454545;
              text-align: justify;
            }
          }
        }
        .contenttwo{
          width: 100%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }
  }
}
@media screen and (min-width: 550px) and (max-width: 650px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    //padding-top: 100px;

    .container{
      width: 100%;
      min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./reacloud.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
          width: 50%;
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-bottom: 120px;
          padding-top: 20px;
          .img1{
            animation: i1 3s ease-in-out infinite;
          }
          .img2{
            animation: i2 3s ease-in-out infinite;
          }
          .img3{
            animation: i3 3s ease-in-out infinite;
          }
          .img4{
            animation: i4 3s ease-in-out infinite;
          }
          img{
            width: 100%;
            //height: 100%;
            position: absolute;
            top: 0;
          }
          @keyframes i1 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(10px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          @keyframes i2 {
            0% {
              transform: translateX(10px) translateY(10px) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(0px) rotate(2deg);
            }
            100% {
              transform: translateX(10px) translateY(10px) rotate(0deg);
            }
          }
          @keyframes i3 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(0px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          @keyframes i4 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(10px) rotate(2deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
        }

        .right{
          width: calc(50% - 60px);
          padding: 60px 60px 60px 0;
          .title{
            font-family: 'ALS';
            font-size: 60pt;
            text-transform: uppercase;
            margin-bottom: 100px;
            color: #410099;
          }
          .pretitle{
            font-size: 40pt;
            text-transform: uppercase;
            color: #595959;
          }
          .desc{

            font-size: 20pt;
            color: #454545;
          }
        }
      }
      .contenttwo{
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .title{
          width: 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          .nameblock{
            font-size: 60pt;
            font-weight: 600;
            color: darkgreen;
            margin-left: 0px;
          }
          .relocation{
            font-size: 16pt;
            border: 1px solid #CCC;
            padding: 20px 40px;
            border-radius: 50px;
            margin-left: 0px;
            margin-top: -5px;
            cursor: pointer;
            transition: 0.3s;
          }
          .relocation:hover{
            border-top: 1px solid #410099;
            background-color: #410099;
            color: #FFF;
            transition: 0.3s;
          }

        }
        .products{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .block{
            width: calc(100% / 2 - 22px);
            height: 500px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            margin: 10px;
            background-color: #FFFFFF;
            align-items: center;
            justify-content: space-between;
            .aplicate{
              width: 100%;
              height: 60%;
              background-size: cover;
              background-position: center center;
            }
            .name{
              font-size: 14pt;
              margin: 10px;
            }
            .category{
              font-size: 12pt;
              padding: 10px 40px;
              border-radius: 30px;
              border: 1px solid #CCC;
              margin: 10px;
            }
            .btncart{
              font-size: 14pt;
              border-top: 1px solid #CCC;
              width: 100%;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: 0.3s;
            }
            .btncart:hover{

              border-top: 1px solid #410099;
              background-color: #410099;
              color: #FFF;
              transition: 0.3s;
            }
          }


        }
      }
      .moreblock{
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .more{
          min-width: 320px;
          width: calc(100% / 5 - 22px);
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FFFFFF;
          border: 1px solid #CCC;
          margin: 0 10px 50px 10px;
          font-size: 20pt;
          cursor: pointer;
          transition: 0.3s;
          color: #454545;
        }
        .more:hover{
          border-top: 1px solid #410099;
          background-color: #410099;
          color: #FFF;
          transition: 0.3s;
        }
      }
    }
    @media (min-width: 1920px) {
      .container {
        background-size: 1920px auto;
        background-repeat: no-repeat;
      }
    }
    @media (max-width: 1200px) {
      .container {
        width: 100%;
        min-height: 100vh;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: url("./reacloud.svg");
        background-size: 100%;
        background-attachment: fixed;
        background-position: center center;
        background-repeat: no-repeat;
        .content{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .left{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              width: 30%;
              animation: moveLeftRight 3s ease-in-out infinite;
              margin-left: 50px;
            }
            @keyframes moveLeftRight {
              0% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
              50% {
                transform: translateX(10px) translateY(10px) rotate(2deg);
              }
              100% {
                transform: translateX(0) translateY(0) rotate(0deg);
              }
            }
          }

          .right{
            width: calc(100% - 120px);
            padding: 60px 60px 60px 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: -150px;
            z-index: 1;
            .title{
              font-family: 'ALS';
              font-size: 60pt;
              text-transform: uppercase;
              margin-bottom: 10px;
              color: #410099;
            }
            .pretitle{
              font-size: 35pt;
              text-transform: uppercase;
              color: #595959;
              text-align: center;
            }
            .desc{

              font-size: 20pt;
              color: #454545;
              text-align: justify;
            }
          }
        }
        .contenttwo{
          width: 100%;
          min-height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;

        }
      }
    }
  }
}
@media screen and (max-width: 550px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2px;
    //padding-top: 100px;

    .container{
      width: 100%;
      min-height: 100vh;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-image: url("./reacloud.svg");
      background-size: 100%;
      background-attachment: fixed;
      background-position: center center;
      background-repeat: no-repeat;
      .content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
        .left{
          width: 60%;
          min-height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          margin-bottom: 20px;
          //margin-right: -40px;
          padding-top: 20px;
          z-index: 0;
          .img1{
            animation: i1 3s ease-in-out infinite;
          }
          .img2{
            animation: i2 3s ease-in-out infinite;
          }
          .img3{
            animation: i3 3s ease-in-out infinite;
          }
          .img4{
            animation: i4 3s ease-in-out infinite;
          }
          img{
            width: 100%;
            //height: 100%;
            position: absolute;
            top: 0;
          }
          @keyframes i1 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(10px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          @keyframes i2 {
            0% {
              transform: translateX(10px) translateY(10px) rotate(0deg);
            }
            50% {
              transform: translateX(0px) translateY(0px) rotate(2deg);
            }
            100% {
              transform: translateX(10px) translateY(10px) rotate(0deg);
            }
          }
          @keyframes i3 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(0px) rotate(0deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
          @keyframes i4 {
            0% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
            50% {
              transform: translateX(10px) translateY(10px) rotate(2deg);
            }
            100% {
              transform: translateX(0) translateY(0) rotate(0deg);
            }
          }
        }

        .right{
          width: calc(100% - 20px);
          padding: 60px 10px 30px 10px;
          z-index: 1;
          .title{
            font-family: 'ALS';
            font-size: 40pt;
            text-transform: uppercase;
            margin-bottom: 20px;
            color: #410099;
            text-align: center;
          }
          .pretitle{
            font-size: 20pt;
            text-transform: uppercase;
            color: #595959;
            text-align: center;
          }
          .desc{

            font-size: 14pt;
            color: #454545;
            text-align: center;
          }
        }
      }
      .contenttwo{
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;
        .title{
          width: 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          align-items: center;
          .nameblock{
            font-size: 30pt;
            font-weight: 600;
            color: darkgreen;
            margin-left: 0px;
          }
          .relocation{
            font-size: 16pt;
            border: 1px solid #CCC;
            padding: 20px 40px;
            border-radius: 50px;
            margin-left: 0px;
            margin-top: -5px;
            cursor: pointer;
            transition: 0.3s;
          }
          .relocation:hover{
            border-top: 1px solid #410099;
            background-color: #410099;
            color: #FFF;
            transition: 0.3s;
          }

        }
        .products{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .block{
            width: calc(100% / 1 - 22px);
            height: 500px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            margin: 10px;
            background-color: #FFFFFF;
            align-items: center;
            justify-content: space-between;
            .aplicate{
              width: 100%;
              height: 60%;
              background-size: cover;
              background-position: center center;
            }
            .name{
              font-size: 14pt;
              margin: 10px;
            }
            .category{
              font-size: 12pt;
              padding: 10px 40px;
              border-radius: 30px;
              border: 1px solid #CCC;
              margin: 10px;
            }
            .btncart{
              font-size: 14pt;
              border-top: 1px solid #CCC;
              width: 100%;
              height: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              transition: 0.3s;
            }
            .btncart:hover{

              border-top: 1px solid #410099;
              background-color: #410099;
              color: #FFF;
              transition: 0.3s;
            }
          }


        }
      }
      .moreblock{
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .more{
          min-width: 320px;
          width: calc(100% / 5 - 22px);
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FFFFFF;
          border: 1px solid #CCC;
          margin: 0 10px 50px 10px;
          font-size: 20pt;
          cursor: pointer;
          transition: 0.3s;
          color: #454545;
        }
        .more:hover{
          border-top: 1px solid #410099;
          background-color: #410099;
          color: #FFF;
          transition: 0.3s;
        }
      }
    }
    @media (min-width: 1920px) {
      .container {
        background-size: 1920px auto;
        background-repeat: no-repeat;
      }
    }

  }
}